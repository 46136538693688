import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/views/home/Page"),
    children: [
      {
        path: "/", // 显示最近阅读
        name: "Home",
        component: () => import("@/views/home/Home.vue"),
      },
      {
        path: "/LatestRead", // 显示最近阅读
        name: "LatestRead",
        component: () => import("@/views/home/LatestRead.vue"),
      },
      {
        path: "/search/DirectSearch", // 直接搜索页
        name: "DirectSearch",
        component: () => import("@/views/search/DirectSearch.vue"),
      },
      {
        path: "/search/IndirectSearch", // 间接搜索页
        name: "IndirectSearch",
        component: () => import("@/views/search/IndirectSearch.vue"),
      },
      {
        path: "/search/retrieveOutput", // 检索输出页
        name: "retrieveOutput",
        component: () => import("@/views/search/retrieveOutput.vue"),
      },
      {
        path: "/retrieval/retrieveDetails", // 论文详情页
        name: "retrieveDetails",
        component: () => import("@/views/retrieval/retrieveDetails.vue"),
      },
      {
        path: "/person", // 个人中心页面
        name: "person",
        component: () => import("@/views/Person/Person.vue"),
        meta: {
          isLogin: true  // 需要登录才能跳转的页面
        },
      },
      {
        path: "/QAdetail", // 个人中心页面
        name: "QAdetail",
        component: () => import("@/views/Person/QAdetail.vue"),
        meta: {
          isLogin: true  // 需要登录才能跳转的页面
        }
      }
    ],
  },
  {
    path: "/Pdf",
    component: () => import("@/views/help/Pdf.vue"),
  },
  {
    path: '/Login',
    component: () => import("@/views/login/Login.vue"),
  },
  {
    path: '/Register',
    component: () => import("@/views/login/Register.vue"),
  },
  {
    path: '/Forget',
    component: () => import("@/views/login/Forget.vue"),
  },
  {
    path: '/conformForget',
    component: () => import("@/views/login/conformForget.vue"),
  },
  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
// 重写vue-router重复点击报错
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
